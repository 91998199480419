<template>
    <div>
        
        <v-btn @click="updateYukiSetting"
                class="mr-2"
                small
                color="warning"
                dark
              >
                <v-icon
                small
                @click="updateYukiSetting"
                v-if="checkUserPermission('EDIT')">
            mdi-pencil
        </v-icon>
        </v-btn>

        <v-btn @click="updateYukiSettingPercent"
                small
                color="success"
                dark
              >
               Percentage Sitting
        </v-btn>

    </div>
</template>

<script>
    import { mapActions } from "vuex";


    export default {
        props: ['darkmode', "item", "params", "dataDelete", "userPermission"],
        data() {
            return {
                loading: false,
                dialogDelete: false,
                userChild: [],
                data1: {
                    descending: true,
                    sortBy: null,
                    page: 1,
                    rowsPerPage: 10,
                    fields: ["name", "is_enable", "is_start", "updatedAt"],
                    search: ''
                }
            };
        },
        computed: {
            
        },
        methods: {
            async updateYukiSetting() {
                
                await this.fetchCurrentYukiSettingData(this.item);
                this.fetchPopUp(true);
            },

            async updateYukiSettingPercent() {
                
                await this.fetchCurrentYukiSettingData(this.item);
                this.fetchPopUpPercent(true);
            },
            ...mapActions("$_yukiSetting", ["fetchPopUp", "fetchPopUpPercent", "fetchCurrentYukiSettingData", "fetchGetAllYukiSetting",]),
        },
    };
</script>
